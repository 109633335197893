body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.marker-container {
  position: absolute;
  top:-34px;
  left: 18px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.hidden-border.left
{
  -webkit-transform: scaleX(-1) translateX(33px);
          transform: scaleX(-1) translateX(33px)
}

.left .marker-group-container
{
  -webkit-transform: scaleX(-1) translateX(10px);
          transform: scaleX(-1) translateX(10px)
}
.marker-container.clear
{
  opacity: 0; 
}
.marker {  
  display:flex;
  align-items:center;
  justify-content:center;
  padding: 15px;
  width: 10px;
  height: 10px;
  color: #0F3656;
  background: #fff;
  border: 6px solid #F99F1C;
  border-radius: 50px / 50px;
  color: black;
  font-size: 24px;
  font-weight: bold;  
}
.marker:after {
  content: "";
  position: absolute;
  bottom: -17px;
  left: 0px;
  width: 0;
  border-width: 25px 8px 0 8px;
  border-style: solid;
  border-color: #F99F1C transparent;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

.marker.expanded:after{
  display: none;
}

.hidden-border {
  /* border: 14px solid red; */
  position: absolute;
}
.hidden-border.group.up .marker-expanded{
  top:-150px;
}

.hidden-border.up .marker-expanded-single{
  top:-50px;
}

.hidden-border.group.down .marker-expanded{
  top:30px;
}

.hidden-border.down .marker-expanded-single{
  top:30px;
}

.marker-expanded{
  position: absolute;
  top:0;
  display: flex;
  flex-direction: column;
  border: 6px solid #FDB812;
  border-radius: 8px;
  background: white;
  width: 300px;
  overflow: auto;
  max-height: 220px;
  margin-top:-80px;
  margin-left:6px;
  background-color: white;
  /* z-index: 1000; */
  left: 22px;
}
.hidden-border:before {
  content: "";
  position: absolute;
  top: -27px;
  left: -1px;
  width: 0;
  height: 0;
  border-bottom: 30px solid #FDB812;
  border-left: 30px solid transparent;
}
.hidden-border-single {
  /* border: 14px solid red; */
  position: absolute;
}
.hidden-border-single:before {
  content: "";
  position: absolute;
  top: -27px;
  left: -1px;
  width: 0;
  height: 0;
  border-bottom: 30px solid #FDB812;
  border-left: 30px solid transparent;
}
.marker-expanded-single{
  position: absolute;
  top:0;
  display: flex;
  flex-direction: column;
  border: 6px solid #FDB812;
  border-radius: 8px;
  background: white;
  width: 300px;
  overflow: auto;
  max-height: 220px;
  margin-top:-80px;
  margin-left:6px;
  background-color: white;
  left: 22px;
  /* z-index: 1000; */
}
.marker-expanded-single:before {
  content: "";
  position: absolute;
  top: -44px;
  left: -19px;
  width: 0;
  height: 0;
  border-bottom: 30px solid #FDB812;
  border-left: 30px solid transparent;
}

.marker-list {
  margin-top: 2px !important;
}
.marker-list li {
  margin-bottom: 0;
}
.marker-group-container {
  margin-left: 10px;
  margin-top: 2px;
  background-color: white;
}
.marker-header {
  margin-top: 0;
  margin-bottom: 0 !important;
  padding: 0 !important;
  font-size: 12px;
  font-weight: bold;
}

.markerGroup {
  display: flex;
  flex-direction: column;
}
.mapsHolder {
  height: 42vh;
  width: 84%;
}
.mapsHeader {
  overflow: visible;
  height: 100px;
  width: 100%;
  top: 0px;
  position: absolute;
 
}
.mapsResultPane {
  overflow: visible;
  top: 100px;
  width: 400px;
  top: 100px;
  position: absolute;
  height: calc(100vh - 100px);
  left: calc(100% - 400px);
  overflow-y: auto;
}
.markerText {
  font-size: 22px;
  font-weight: bold;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

